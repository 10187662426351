<template>
  <div>
    <AppBar title="Phonebook" :user="user" />
    <v-container v-if="testCollection.length && testDocument">
      <v-row justify="center">
        <v-col cols="12" sm="10" md="8" lg="6">
          <v-card ref="form">
            <v-card-text>
              <div class="text-center">
                <h2>LeftBrain Phone Number: 0203 026 9942</h2>
                <v-overlay :value="isLoading" :absolute="absolute">
                  <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>

                <v-overlay :value="hasLoaded" :absolute="absolute">
                  <h1>
                    Sent!
                  </h1>
                  <br>
                    <v-btn color="secondary" @click="resetForm">Reload
                      <v-icon right>mdi-refresh</v-icon>
                    </v-btn>
                </v-overlay>
              </div>
              <br />

              <v-text-field v-model="name" label="Name" outlined></v-text-field>
              <v-text-field v-model="company" label="Company" outlined></v-text-field>
              <v-text-field v-model="number" label="Phone Number" outlined></v-text-field>
              <v-text-field v-model="email" label="Email Address" outlined></v-text-field>
              <v-textarea outlined v-model="message" label="Message"></v-textarea>
              <v-radio-group v-model="radios" :mandatory="true">
                <v-radio label="New Business" value="newbusiness"></v-radio>
                <v-radio label="Support" value="support"></v-radio>
              </v-radio-group>
            </v-card-text>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-slide-x-reverse-transition>
                <v-tooltip left>
                  <template>
                    <v-btn color="secondary" @click="resetForm">Reload
                      <v-icon right>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </v-slide-x-reverse-transition>
              <v-btn color="primary" @click="submit">Submit
                <v-icon right >mdi-send</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AppBar from "../components/AppBar.vue";
import { firestore } from "../firebase";
import axios from "axios";

export default {
  props: ["user"],
  data() {
    return {
      name: null,
      company: null,
      number: null,
      email: null,
      message: null,
      isLoading: false,
      hasLoaded: false,
      absolute: true,
      status: "null",
      radios: "support",
      testCollection: [],
      testDocument: null
    };
  },
  computed: {
    form() {
      return {};
    }
  },
  components: {
    AppBar
  },
  firestore: {
    testCollection: firestore().collection("testCollection"),
    testDocument: firestore()
      .collection("testCollection")
      .doc("Hkr0Bwri2ugph26eke6w")
  },
  methods: {
    save() {
      firestore()
        .collection("testCollection")
        .doc("Hkr0Bwri2ugph26eke6w")
        .update(this.testDocument);
    },
    resetForm() {
      console.log("Reset button pressed");
      this.name = "";
      this.radios = "support";
      this.number = "";
      this.company = "";
      this.email = "";
      this.message = "";
      this.isLoading = false;
      this.hasLoaded = false;
    },
    submit() {
      //axios post to Zapier for it to do the rest including storing the information sent.
      //Vue loading plus small delay
      //reset form on successful submittion
      console.log("Submit button pressed");
      this.isLoading = true;

      axios({
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        url: "https://hooks.zapier.com/hooks/catch/480070/o1uqvpa/",
        data: {
          name: this.name,
          company: this.company,
          number: this.number,
          email: this.email,
          message: this.message,
          radios: this.radios
        }
      })
        .then(function(response) {
          console.log(response);
        })
        .catch(function(error) {
          console.log(error);
        });
      setTimeout(() => {
        this.isLoading = false;
        this.hasLoaded = true;
      }, 3000);
    }
  }
};
</script>